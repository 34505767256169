var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "firmware-detail" },
    [
      _vm.title
        ? _c("app-header", { attrs: { title: _vm.title, isShowBack: true } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "firmware-form" },
        [
          _c(
            "el-form",
            { ref: "myForm", attrs: { model: _vm.myForm, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "货柜型号",
                    prop: "counterType"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择货柜型号" },
                      on: { change: _vm.counterChange },
                      model: {
                        value: _vm.myForm.counterType,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "counterType", $$v)
                        },
                        expression: "myForm.counterType"
                      }
                    },
                    _vm._l(_vm.containMoleList, function(it) {
                      return _c("el-option", {
                        key: it.value,
                        attrs: { label: it.label, value: it.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "固件版本",
                    prop: "version"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.notEdit,
                      placeholder: "请输入固件版本"
                    },
                    model: {
                      value: _vm.myForm.version,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "version", $$v)
                      },
                      expression: "myForm.version"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "从柜固件版本",
                    prop: "subHwVersion"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.notEdit,
                      placeholder: "请输入从柜固件版本"
                    },
                    model: {
                      value: _vm.myForm.subHwVersion,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "subHwVersion", $$v)
                      },
                      expression: "myForm.subHwVersion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "上传固件",
                    prop: "fileUrl"
                  }
                },
                [
                  _vm.showFlag
                    ? _c("el-input", {
                        model: {
                          value: _vm.myForm.fileUrl,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "fileUrl", $$v)
                          },
                          expression: "myForm.fileUrl"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "up_add_bg",
                      on: { click: _vm.fileuploadBtn }
                    },
                    [
                      _vm._v(
                        "\n                    上传\n                    "
                      ),
                      _c("input", {
                        ref: "inputer",
                        staticClass: "upload-btn",
                        attrs: {
                          type: "file",
                          id: "fileupload",
                          multiple: "false",
                          accept:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        },
                        on: { change: _vm.addFile }
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "120px", label: "固件文件名" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "请先上传固件" },
                    model: {
                      value: _vm.myForm.fileName,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "fileName", $$v)
                      },
                      expression: "myForm.fileName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "120px", label: "固件大小" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "请先上传固件" },
                    model: {
                      value: _vm.myForm.fileSize,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "fileSize", $$v)
                      },
                      expression: "myForm.fileSize"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "版本说明",
                    prop: "remark"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 14,
                      maxlength: "200",
                      placeholder: "请输入版本说明",
                      "show-word-limit": "",
                      disabled: _vm.notEdit
                    },
                    model: {
                      value: _vm.myForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "remark", $$v)
                      },
                      expression: "myForm.remark"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.notEdit,
                      expression: "!notEdit"
                    }
                  ],
                  staticClass: "submitBox"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            width: "500px",
            top: "30vh",
            visible: _vm.dialogVisibleUpload,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleUpload = $event
            },
            close: function($event) {
              _vm.closeFun()
            }
          }
        },
        [
          _c("div", { staticClass: "dialog_content" }, [
            _c("img", {
              attrs: { src: require("../../assets/loadanimation.gif") }
            }),
            _c("div", [_vm._v("固件上传中请稍后...")])
          ]),
          _c(
            "div",
            { staticClass: "dialog_bottom" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sureBtn } },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }