<template>
    <div class="firmware-detail">
        <app-header v-if="title" :title="title" :isShowBack="true"></app-header>
        <div class="firmware-form">
            <el-form ref="myForm" :model="myForm" :rules="rules">
                <el-form-item label-width="120px" label="货柜型号" prop="counterType">
                    <el-select v-model="myForm.counterType" placeholder="请选择货柜型号" @change="counterChange">
                        <el-option v-for="it in containMoleList" :key="it.value" :label="it.label" :value="it.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="120px" label="固件版本" prop="version">
                    <el-input v-model="myForm.version" :disabled="notEdit" placeholder="请输入固件版本">
                    </el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="从柜固件版本" prop="subHwVersion">
                    <el-input v-model="myForm.subHwVersion" :disabled="notEdit" placeholder="请输入从柜固件版本">
                    </el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="上传固件" prop="fileUrl">
                    <el-input v-model="myForm.fileUrl" v-if="showFlag">
                    </el-input>
                    <!-- <el-button type="primary">上传</el-button> -->
                    <div class="up_add_bg" @click='fileuploadBtn'>
                        上传
                        <input type="file" class="upload-btn" @change="addFile" ref="inputer" id="fileupload"
                            multiple='false'
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                    </div>
                </el-form-item>
                <el-form-item label-width="120px" label="固件文件名">
                    <el-input v-model="myForm.fileName" disabled placeholder="请先上传固件">
                    </el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="固件大小">
                    <el-input v-model="myForm.fileSize" disabled placeholder="请先上传固件">
                    </el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="版本说明" prop="remark">
                    <el-input type="textarea" :rows="14" maxlength="200" placeholder="请输入版本说明" v-model="myForm.remark"
                        show-word-limit :disabled="notEdit">
                    </el-input>
                </el-form-item>
                <div class="submitBox" v-show="!notEdit">
                    <el-button type="primary" @click="submit()">提交</el-button>
                </div>
            </el-form>
        </div>

        <el-dialog title="" width="500px" top="30vh" :visible.sync="dialogVisibleUpload" @close="closeFun()"
            :close-on-click-modal="false">
            <div class="dialog_content">
                <img src="../../assets/loadanimation.gif">
                <div>固件上传中请稍后...</div>
            </div>
            <div class="dialog_bottom">
                <el-button type="primary" @click="sureBtn">关闭</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    import axios from "axios";
    let versionVli = (rule, value, callback) => {
        console.log('value:', value)
        let reg = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
        let reg1 = /^.{2,64}$/;
        if (reg.test(value)) {
            callback(new Error("只能包含大小写字母、数字、英文符号"));
        } else if (value && !reg1.test(value)) {
            callback('长度只能为2~64个字符');
        } else if (value == "" || value == undefined) {
            callback('请输入固件版本')
        } else {
            callback();
        }
    };
    export default {
        data() {
            return {
                userInfo: '',
                showFlag: false,
                dialogVisibleUpload: false,
                formData: new FormData(),
                file: {}, //文件数据
                notEdit: true,
                title: '上传固件',
                containMoleList: [],
                myForm: {
                    counterType: '',
                    version: '',
                    subHwVersion: '',
                    remark: '',
                    fileName: '',
                    fileSize: '',
                    fileUrl: '',
                    mdAddr: '',
                },
                rules: {
                    counterType: [{
                        required: true,
                        message: "请选择货柜型号",
                        trigger: ['change']
                    }],
                    version: [{
                        required: true,
                        trigger: ['change'],
                        validator: versionVli
                    }],
                    subHwVersion: [{
                        required: true,
                        message: "请输入从柜固件版本",
                        trigger: ['change']
                    }],
                    remark: [{
                        required: true,
                        message: "请输入版本说明",
                        trigger: ['change']
                    }],
                    fileUrl: [{
                        required: true,
                        message: "请上传固件",
                        trigger: ['change']
                    }],
                }

            };
        },
        async activated() {
            this.userInfo = this.cache.getLS("userInfo");
            console.log("firmware-detail activated!!");
            if (this.$route.params.type == "show") {
                this.notEdit = true;
            } else {
                this.notEdit = false;
            }
            this.title = ["添加固件", "编辑固件", "固件详情"][{
                add: 0,
                edit: 1,
                show: 2
            } [this.$route.params.type]];
            this.myForm = {
                counterType: '',
                version: '',
                subHwVersion: '',
                remark: '',
                fileName: '',
                fileSize: '',
                fileUrl: '',
                mdAddr: '',
            };
            this.rules.subHwVersion[0].required = false
            this.$nextTick(() => {
                if (this.$refs["myForm"]) {
                    this.$refs["myForm"].resetFields();
                }
            })

            if (this.$route.params.type == "edit" || this.$route.params.type == "show") {
                this.getDeatil();
            }
            try {
                this.containMoleList = await cabinetUtil.getContainerModel();
            } catch (error) {
                console.log(error)
            }
        },
        methods: {
            submit() {
                this.$refs["myForm"].validate((valid) => {
                    if (!valid) {
                        return;
                    } else {
                        let dto = {
                            counterType: this.myForm.counterType,
                            version: this.myForm.version,
                            subHwVersion: this.myForm.subHwVersion,
                            remark: this.myForm.remark,
                            fileName: this.myForm.fileName,
                            fileSize: this.myForm.fileSize,
                            fileUrl: this.myForm.fileUrl,
                            mdAddr: this.myForm.mdAddr,
                            upgradeUser: this.userInfo.account,
                        }
                        if (this.myForm.counterType == 4) {
                            dto.counterType = 6;
                            dto.size = 1;
                        } else if (this.myForm.counterType == 5) {
                            dto.counterType = 6;
                            dto.size = 2;
                        } else if (this.myForm.counterType == 6) {
                            dto.counterType = 7;
                            dto.size = 1;
                        } else if (this.myForm.counterType == 7) {
                            dto.counterType = 7;
                            dto.size = 2;
                        } else {
                            // 其它货柜类型
                            dto.type = this.myForm.counterType;
                        }
                        let url = '/mall-service/v1/mall/firmware/save';
                        let message = '添加成功！'
                        if (this.$route.params.type == 'edit') {
                            message = '修改成功！'
                            dto.id = this.$route.query.id;
                            url = '/mall-service/v1/mall/firmware/update';
                        }
                        this.post(url, dto, {
                            isUseResponse: true,
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    showClose: true,
                                    message: message,
                                    type: "success"
                                });
                                this.$back();
                            }
                        })
                    }
                })
            },
            getDeatil() {
                this.get('/mall-service/v1/mall/firmware/detail/' + this.$route.query.id).then(res => {
                    this.myForm.counterType = cabinetUtil.getContainerTypeName(res.counterType);
                    this.myForm.counterType = res.counterType;
                    this.myForm.version = res.version;
                    this.myForm.subHwVersion = res.subHwVersion;
                    this.myForm.fileUrl = res.fileUrl;
                    this.myForm.fileName = res.fileName;
                    this.myForm.fileSize = res.fileSize;
                    this.myForm.remark = res.remark;
                    this.myForm.mdAddr = res.mdAddr;
                    this.counterChange(this.myForm.counterType)
                })
            },
            fileuploadBtn() {
                document.getElementById('fileupload').click();
            },
            counterChange(val) {
                let chooseVal = []
                this.containMoleList.forEach(item => {
                    if(item.label.indexOf('WF-L25') != -1 && item.label != "WF-L25AM"){
                        chooseVal.push(item.value)
                    }
                })
                if(chooseVal.indexOf(val) == -1){
                    this.rules.subHwVersion[0].required = false
                }else{
                    this.rules.subHwVersion[0].required = true
                }
            },
            //选择文件
            addFile: function (e) {
                var _this = this;
                _this.dialogVisibleUpload = true;
                let inputDOM = this.$refs.inputer;
                this.file = inputDOM.files[0];
                let len = this.file.length;
                let size = Math.floor(this.file.size / 1024);
                this.myForm.fileSize = size + 'KB';
                if (size > 50 * 1024 * 1024) {
                    alert("请选择50M以内的文件！");
                    return false;
                }
                this.formData.append("file", this.file);
                axios({
                    method: "POST",
                    url: _this.UPLOAD_URL + "/mall-service/v1/mall/firmware/upload",
                    data: this.formData,
                    headers: {
                        accessToken: localStorage.getItem("token") || sessionStorage.getItem("token"),
                        securityKey: localStorage.getItem("securityKey") || sessionStorage.getItem(
                            "securityKey"),
                        'Content-Type': 'multipart/form-data',
                    },
                }).then((response) => {
                    this.formData = new FormData();
                    this.myForm.fileName = document.getElementById('fileupload').files[0].name;
                    if (response.data.code == 0) {
                        this.$message({
                            type: 'success',
                            message: '上传成功！'
                        })
                        this.dialogVisibleUpload = false;
                        // 上传成功后返回的路径
                        this.myForm.fileUrl = response.data.data.fileUrl;
                        this.myForm.mdAddr = response.data.data.mdAddr;
                        //刷新下必填项的校验状态，解决上传固件成功后仍提示校验规则语rule
                        this.$refs["myForm"].validate((valid) => {})
                    } else {
                        this.$message({
                            type: 'warning',
                            message: response.data.msg
                        })
                    }
                    e.target.value = ''; //清空input中的value值 避免同一个文件二次上传无效的问题
                }).catch((error) => {
                    this.dialogVisibleUpload = false;
                    this.formData = new FormData();
                    e.target.value = ''; //清空input中的value值 避免同一个文件二次上传无效的问题
                });
            },
            closeFun() {
                this.dialogVisibleUpload = false;
            },
            sureBtn() {
                this.dialogVisibleUpload = false;
            },
        }
    };
</script>
<style lang="scss" scoped>
    .el-form-item {
        width: 700px;
    }

    .up_add_bg {
        width: 95px;
        text-align: center;
        height: 33px;
        line-height: 33px;
        background: #30BAC1;
        border: 1px solid #CFCFD7;
        border-radius: 6px;
        cursor: pointer;
        color: #ffffff;

        .upload-btn {
            opacity: 0;
        }
    }

    .dialog_content {
        text-align: center;
        padding-bottom: 40px;
    }

    .dialog_bottom {
        text-align: center;
    }

    .firmware-detail {
        min-width: 1250px;
    }

    .firmware-form {
        padding: 0px 90px;
    }

    .firmware-form-groupCode {
        font-size: 14px;
        color: #999;
        padding-left: 56px;
        padding-bottom: 20px;
    }

    .submitBox {
        text-align: center;
        width: 745px;
    }
</style>